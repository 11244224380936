<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Editing Product Skeleton </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <trev-product-skeleton-add
        @updated-product-skeleton="redirectToView"
          :showTitle="false"
          v-if="prodSkel.id"
          :productSkeletonId="prodSkel.id"
          :companyId="prodSkel.parentCompanyId"
        >
        </trev-product-skeleton-add>
        
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import trevProductSkeletonAdd from '../../../../../components/products/productskeletons/trev-product-skeleton-add.vue';
export default {
  components: { trevProductSkeletonAdd },
  created () {
    //get the prod skel

    this.$courier.ProductSkeletons.getById(this.$route.params.productSkeletonId).then(pSkel => {
        this.prodSkel = pSkel;
    });
  },
    methods: {
        redirectToView() {
            this.$router.push({
                name: 'Product Skeleton View',
                params: {
                    productSkeletonId: this.$route.params.productSkeletonId
                }
            })
        }
    },
    data() {
        return {
            prodSkel: {}
        }
    },
};
</script>

<style>
</style>